<template>
    <div class="container">
        <UpDateModal v-model:upDateDept="upDateDept" @eventUpdateOk="getDeptTreeData" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <vue3-tree-org
                ref="treeRef"
                :disabled="true"
                :data="deptData"
                :horizontal="false"
                :collapsable="true"
                :node-draggable="false"
                :define-menus="defineMenus"
                :toolBar="{
                    scale: false,
                    restore: false,
                    expand: false,
                    zoom: false,
                    fullscreen: false,
                }"
                :filter-node-method="filterNodeMethod"
                @on-contextmenu="onMenus"
            >
                <!-- 自定义节点内容 -->
                <template v-slot="{ node }">
                    <div class="tree-org-node__text node-label">
                        <div>{{ node.label }}</div>
                        <!-- <div> {{ node.$$data.code }}</div> -->
                    </div>
                </template>
                <!-- 自定义展开按钮 -->
                <!-- <template v-slot:expand="{ node }">
                    <div>{{ node.children.length }}</div>
                </template> -->
            </vue3-tree-org>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import { confirmModal, showMessage } from "@/utils/common";
import { apiDeptaTree, apiDeptEnable, apiDeleteDept } from "@/api";
import { useStore } from "vuex";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
    },
    setup() {
        const { getters } = useStore();
        const state = reactive({
            upDateDept: null,
            params: null,
            loading: false,
            treeRef: null,
            deptData: {},
            onStyle: {
                color: "#1890ff",
                background: " #ecf5ff",
            },
            offStyle: {
                color: "#909399",
                background: "#f4f4f5",
            },
        });

        const filterNodeMethod = (value, data) => {
            if (!value) return true;
            return data.label == value;
        };

        const defineMenus = (e, node) => {
            // 这里不能写add delete 不然不出来菜单 是因为 :disabled="true"被禁用了 组件自带的添加删除
            let { id, enabled } = node.$$data;
            const menus = [
                getters["app/btnPerms"]["btn:dept-add"] && { name: "添加", command: "add1" },
            ];
            return id == 1 //顶级部门禁止操作
                ? menus
                : [
                      ...menus,
                      getters["app/btnPerms"]["btn:dept-edit"] && {
                          name: "编辑",
                          command: "edit1",
                      },
                      getters["app/btnPerms"]["btn:dept-delete"] && {
                          name: "删除",
                          command: "delete1",
                      },
                      { name: enabled ? "禁用" : "启用", command: "changeSwitch" },
                  ];
        };

        const onMenus = ({ node, command }) => {
            let deptInfo = node.$$data;
            const commandFn = {
                changeSwitch: () => changeSwitch(deptInfo.enabled, deptInfo.id),
                edit1: () =>
                    (state.upDateDept = {
                        ...deptInfo,
                        type: "edit",
                    }),
                add1: () =>
                    (state.upDateDept = {
                        ...deptInfo,
                        type: "add",
                    }),
                delete1: () => deleteDept(deptInfo.id),
            };
            commandFn[command]();
        };

        const search = params => {
            state.params = params;
            state.treeRef.filter(state.params.name);
        };

        const cycle = arr => {
            return arr.map(item => {
                item.label = item.name;
                item.style = item.enabled ? state.onStyle : state.offStyle;
                item.expand = true;
                if (item.children && item.children.length) {
                    cycle(item.children);
                }
                return item;
            });
        };

        const getDeptTreeData = async () => {
            let res = await apiDeptaTree();
            if (res.status === "SUCCESS") {
                state.deptData = cycle([res.data])[0];
            }
        };

        onMounted(() => {
            getDeptTreeData();
        });

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "部门禁用后，之后将无法在业务流程中使用该部门信息，是否确认禁用？"
                : "部门启用后，该部门可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiDeptEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getDeptTreeData();
                    }
                },
            });
        };

        const deleteDept = id => {
            confirmModal({
                type: "warning",
                content: "部门删除后将无法恢复，是否确认删除该部门？",
                onOk: async () => {
                    let res = await apiDeleteDept([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getDeptTreeData();
                    }
                },
            });
        };

        return {
            ...toRefs(state),
            changeSwitch,
            search,
            filterNodeMethod,
            onMenus,
            defineMenus,
            getDeptTreeData,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.zoom-container) {
    display: flex;
    // justify-content: center;
}
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        height: 100vh;
    }
}
.tree-org-node__text,
.node-label {
    > div {
        white-space: pre-wrap;
    }
}
</style>
