<template>
    <a-modal
        v-model:visible="visible"
        :title="`${isAddDept ? '新增' : '编辑'}部门`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="部门名称"
                name="name"
                :rules="[{ required: true, message: '请输入部门名称' }, { validator: checkName }]"
            >
                <a-input v-model:value="formData.name" :maxlength="20" showCount />
            </a-form-item>

            <a-form-item v-if="!isAddDept" label="部门编码" name="code">
                <a-input v-model:value="formData.code" disabled />
            </a-form-item>

            <a-form-item
                label="部门描述"
                name="description"
                :rules="[{ validator: checkDescription }]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入300字"
                    :rows="4"
                    :maxlength="300"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiUpdateDept, apiDeptIndexData } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
export default defineComponent({
    props: ["upDateDept"],
    emits: ["update:upDateDept", "eventUpdateOk"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            isAddDept: computed(() => props.upDateDept?.type == "add"),
            formData: {
                id: "",
                name: "",
                code: "",
                pId: "",
                description: "",
            },
            parentDeptCandidate: [],
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateDept", null);
        };

        const onFinish = async () => {
            let { name } = state.formData;
            state.formData.name = name?.trim();
            let res = await apiUpdateDept(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const checkValueIsHasSpace = async (rule, value) => {
            if (!value) return;
            if (checkValueIsAvailable(value, "space")) {
                return Promise.reject("不能包含空格");
            }
        };

        watch(
            () => props.upDateDept,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    let { id, pId, code, name, description } = newVal;
                    if (state.isAddDept) {
                        state.formData.id = null;
                        state.formData.code = "";
                        state.formData.name = "";
                        state.formData.pId = id;
                        state.formData.description = "";
                    } else {
                        state.formData.id = id;
                        state.formData.code = code;
                        state.formData.name = name;
                        state.formData.pId = pId;
                        state.formData.description = description;
                    }
                } else {
                    state.visible = false;
                }
            }
        );

        const checkName = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const checkDescription = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkValueIsHasSpace,
            checkName,
            checkDescription,
        };
    },
});
</script>
